html, body {
    width:100%;
    height:100%;
    margin:0; 
    padding:0;
    overflow: hidden;
}


@media screen and (min-width: 1023px) {
    html, body {
        width:100%;
        height:100%;
        margin:0; 
        padding:0;
        overflow: auto;
    }
}
#root{
    width:100%;
    height:100%;
    margin:0; 
    padding:0;
}

#root::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#root {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
html::-webkit-scrollbar {
    display: none;
}
body::-webkit-scrollbar {
    display: none;
}

html,body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }