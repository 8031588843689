.headerContainer{
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    margin: 32px 0px;
    align-self: flex-start;
    align-items: center;
    cursor: pointer;
}

.logoStyles{
    width: auto;
    height: 36px;
}

.logoImageStyles{
    width: auto;
    height: 66px;
}


@media screen and (max-width: 767px){
    .logoStyles{
        width: auto;
        height: 2cqmax;
    }
    
    .logoImageStyles{
        width: auto;
        height: 46px;
    }
}
