    .footerWrapper{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        justify-content: center;
        border-top: 1px solid rgb(252, 108, 12, 0.5);
        background: 
        linear-gradient(1turn,rgba(252,108,12,.2) -6.9%,transparent 16.55%),
        linear-gradient(1turn,rgba(252,108,12,.2) -8.1%,rgba(252,108,12,0) 50%);
    }

    .getInTouchText{
        color: #FC6D0D;
        font-family: Poppins,sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        margin-top: 16px;
    }
    .socialsContainer{
        display: flex;
        flex-direction: row;
        gap: 52px;
        margin-bottom: 16px;
    }

    .socialsIcon{
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
    .emailText{
        color: #FFF;
        font-family: Poppins,sans-serif;
        font-size: 24px;
        font-weight: 600;
        margin-top:16px;
        margin-bottom:24px;
        cursor: pointer;
    }
    .copyrightText{
        color: #FFF;
        font-family: Poppins,sans-serif;
        font-size: 16px;
        font-weight: 600;
        margin-top:16px;
        margin-bottom:24px;
        cursor: pointer;
    }

    .socialsIcon:hover{
        filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
    }


    @media screen and (max-width: 1023px){
        .footerWrapper{
             width: 111%;
        }
        .getInTouchText{
            margin-top: 32px;
        }
        .socialsContainer{
            margin-bottom: 0;
        }
    }