
@media screen and (min-width: 1024px){
    .wrapper{
        display: flex;
        height: 100%;
        width: 100%;
        overflow: scroll;
        background-color: #191919;
        flex: 1;
        justify-content: center;
        align-items: center;
    }
    .innerContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 60%;
        height: 100%;
        gap: 36px;
    }
}

@media screen and (max-width: 1023px){
    .wrapper{
        display: flex;
        height: 100%;
        width: 100%;
        overflow: scroll;
        background-color: #191919;
        flex: 1;
        justify-content: center;
        align-items: center;
    }
    .innerContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 90%;
        height: 100%;
        gap: 36px;
    }
}



@media screen and (max-width: 767px) and (max-height: 650px){
}

@media screen and (max-height: 850px) and (min-width: 1024px) {
}


.wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrapper {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
