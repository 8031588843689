   .leftTop{
        position: absolute;
        width: 200px;
        height: auto;
        top: 0;
        left: 0;
    }
    .leftMid{
        position: absolute;
        width: 200px;
        height: auto;
        top: 50%;
        left: 0;
        transform: rotate(160deg);
    }
    .leftBottom{
        position: absolute;
        width: 200px;
        height: auto;
        left: 0;
        bottom: 0;
    }
    .rightBottom{
        position: absolute;
        width: 200px;
        height: auto;
        right: 0;
        bottom: 0;
    }
    .rightMid{
        position: absolute;
        width: 200px;
        height: auto;
        top: 50%;
        right: 0;
        transform: rotate(160deg);
    }
    .socialsIcon:hover{
        filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
    }

    
    .wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrapper {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Desktop */
@media screen and (min-width: 1441px){
    .wrapper{
        background-color: #000;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .innerContainer{
        display: flex;
        flex:1;
        width: 100%;
        background-color: rgba(30,30,30,0);
        height: 100%;
        max-width: 1000px;
        max-height: calc(100% - 154px);
        border: 2px solid #FC6C0C;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        position: relative;
        margin: 76px 187px;
        gap: 16px;
        background-color:linear-gradient( #FC6C0C, #1E1E1E00);
        border-radius: 6px;
    }
    .logoStyles{
        width: 220px;
        height: auto;
        margin-top: 48px;
    }
    .logoImageStyles{
        width: 110px;
        height: auto;
    }
    .comingSoonText{
        color: #FFF;
        font-family: Montserrat;
        font-size: 54px;
        letter-spacing: 20px;
        font-weight: 600;
    }
    .getInTouchText{
        color: #FC6D0D;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }
    .socialsContainer{
        display: flex;
        flex-direction: row;
        gap: 52px;
        margin-bottom: 16px;
    }

    .socialsIcon{
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    .innerContainerSocialsAndText{
        display: flex;
        flex-direction: column;
        gap: 32px;
        justify-content: center;
        align-items: center;
    }
    .innerContainerContactAndEmail{
        display: flex;
        flex-direction: column;
        gap: 32px;
        justify-content: center;
        align-items: center;
    }
    .emailText{
        color: #FFF;
        font-family: Montserrat;
        font-size: 32px;
        font-weight: 600;
        margin-top:16px;
        margin-bottom:24px;
        cursor: pointer;
    }
    .reverseContainers{
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    .reverseContainerHeader{
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
    }
}

/* Laptop */
@media screen and (min-width: 1024px) and (max-width: 1440px){
    .wrapper{
        background-color: #000;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .innerContainer{
        display: flex;
        flex:1;
        width: 100%;
        background-color: rgba(30,30,30,0);
        height: 100%;
        max-height: calc(100% - 154px);
        border: 2px solid #FC6C0C;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
        margin: 76px 96px;
        gap: 32px;
        background-color:linear-gradient( #FC6C0C, #1E1E1E00);
        border-radius: 6px;
    }
    .logoStyles{
        width: 220px;
        height: auto;
        margin-top: 48px;
    }
    .logoImageStyles{
        width: 110px;
        height: auto;
    }
    .comingSoonText{
        color: #FFF;
        font-family: Montserrat;
        font-size: 54px;
        letter-spacing: 20px;
        font-weight: 600;
    }
    .getInTouchText{
        color: #FC6D0D;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }
    .socialsContainer{
        display: flex;
        flex-direction: row;
        gap: 52px;
        margin-bottom: 16px;
    }
    .socialsIcon{
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    .innerContainerSocialsAndText{
        display: flex;
        flex-direction: column;
        gap: 32px;
        justify-content: center;
        align-items: center;
    }
    .innerContainerContactAndEmail{
        display: flex;
        flex-direction: column;
        gap: 32px;
        justify-content: center;
        align-items: center;
    }
    .emailText{
        color: #FFF;
        font-family: Montserrat;
        font-size: 32px;
        font-weight: 600;
        margin-top:16px;
        cursor: pointer;
    }
    .reverseContainers{
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    .reverseContainerHeader{
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
    }
}

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1023px){
    .wrapper{
        height: -webkit-fill-available;
        width: -webkit-fill-available;
    }
    .innerContainer{
        display: flex;        
        background-color: #000;
        flex:1;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        gap: 32px;
    }
    .logoStyles{
        width: 220px;
        height: auto;
        margin-top: 24px;
    }
    .logoImageStyles{
        width: 110px;
        height: auto;
    }
    .comingSoonText{
        color: #FFF;
        font-family: Montserrat;
        font-size: 54px;
        letter-spacing: 20px;
        font-weight: 600;
        margin: 48px 0px;
    }
    .getInTouchText{
        color: #FC6D0D;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }
    .socialsContainer{
        display: flex;
        flex-direction: row;
        gap: 52px;
        margin-bottom: 16px;
    }
    .socialsIcon{
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    .innerContainerSocialsAndText{
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        align-items: center;
    }
    .innerContainerContactAndEmail{
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        align-items: center;
    }
    .emailText{
        color: #FFF;
        font-family: Montserrat;
        font-size: 32px;
        font-weight: 600;
        cursor: pointer;
    }
    .reverseContainers{
        display: flex;
        flex-direction: column-reverse;
        gap: 32px;
    }
    .reverseContainerHeader{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) and (max-height: 650px){
    .wrapper{
        background-color: #000;
        height: -webkit-fill-available;
        width: -webkit-fill-available;
        overflow-y: scroll;
    }
    .innerContainer{
        display: flex;        
        background-color: #000;
        flex:1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        gap: 32px;
        height: fit-content;
        max-height: fit-content;
        padding: 32px 0px;
    }
    .logoStyles{
        width: 220px;
        height: auto;
        margin-top: 24px;
    }
    .logoImageStyles{
        width: 110px;
        height: auto;
    }
    .comingSoonText{
        color: #FFF;
        font-family: Montserrat;
        font-size: 54px;
        letter-spacing: 20px;
        font-weight: 600;
        margin: 48px 0px;
    }
    .getInTouchText{
        color: #FC6D0D;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }
    .socialsContainer{
        display: flex;
        flex-direction: row;
        gap: 52px;
        margin-bottom: 16px;
    }
    .socialsIcon{
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    .innerContainerSocialsAndText{
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        align-items: center;
    }
    .innerContainerContactAndEmail{
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        align-items: center;
    }
    .emailText{
        color: #FFF;
        font-family: Montserrat;
        font-size: 32px;
        font-weight: 600;
        cursor: pointer;
    }
    .reverseContainers{
        display: flex;
        flex-direction: column-reverse;
        gap: 32px;
    }
    .reverseContainerHeader{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
}

/* Mobile */
@media screen and (max-width: 767px){
    .wrapper{
        background-color: #000;
        height: -webkit-fill-available;
        width: -webkit-fill-available;
    }
    .innerContainer{
        display: flex;        
        background-color: #000;
        flex:1;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        gap: 32px;
    }
    .logoStyles{
        width: 110px;
        height: auto;
        margin-top: 24px;
    }
    .logoImageStyles{
        width: 70px;
        height: auto;
    }
    .comingSoonText{
        color: #FFF;
        font-family: Montserrat;
        font-size: 28px;
        letter-spacing: 7.68px;
        font-weight: 600;
        margin: 48px 0px;
    }
    .getInTouchText{
        color: #FC6D0D;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
    }
    .socialsContainer{
        display: flex;
        flex-direction: row;
        gap: 52px;
        margin-bottom: 16px;
    }
    .socialsIcon{
        width: 32px;
        height: auto;
        cursor: pointer;
    }
    .innerContainerSocialsAndText{
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        align-items: center;
    }
    .innerContainerContactAndEmail{
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        align-items: center;
    }
    .emailText{
        color: #FFF;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        cursor: pointer;
    }
    .reverseContainers{
        display: flex;
        flex-direction: column-reverse;
        gap: 32px;
    }
    .reverseContainerHeader{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
    .leftMid{
        position: absolute;
        width: 150px;
        height: auto;
        top: 0;
        left:70%;
        transform: rotate(262deg);
    }
    .rightMid{
        position: absolute;
        width: 100px;
        height: auto;
        top: 50%;
        right: 0;
        transform: rotate(160deg);
    }
    .leftTop{
        position: absolute;
        width: 200px;
        height: auto;
        top: 20;
        left: -20;
        transform: rotate(11deg);
    }
  
    .leftBottom{
        position: absolute;
        width: 100px;
        height: auto;
        left: 0;
        bottom: 0;
    }
    .rightBottom{
        position: absolute;
        width: 100px;
        height: auto;
        right: 0;
        bottom: 0;
    }
}
@media screen and (max-width: 767px) and (max-height: 650px){
    .wrapper{
        background-color: #000;
        height: -webkit-fill-available;
        width: -webkit-fill-available;
        overflow-y: scroll;
    }
    .innerContainer{
        display: flex;        
        background-color: #000;
        flex:1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        gap: 32px;
        height: fit-content;
        max-height: fit-content;
        padding: 32px 0px;
    }
    .logoStyles{
        width: 110px;
        height: auto;
        margin-top: 24px;
    }
    .logoImageStyles{
        width: 70px;
        height: auto;
    }
    .comingSoonText{
        color: #FFF;
        font-family: Montserrat;
        font-size: 28px;
        letter-spacing: 7.68px;
        font-weight: 600;
        margin: 48px 0px;
    }
    .getInTouchText{
        color: #FC6D0D;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
    }
    .socialsContainer{
        display: flex;
        flex-direction: row;
        gap: 52px;
        margin-bottom: 16px;
    }
    .socialsIcon{
        width: 32px;
        height: auto;
        cursor: pointer;
    }
    .innerContainerSocialsAndText{
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        align-items: center;
    }
    .innerContainerContactAndEmail{
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        align-items: center;
    }
    .emailText{
        color: #FFF;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        cursor: pointer;
    }
    .reverseContainers{
        display: flex;
        flex-direction: column-reverse;
        gap: 32px;
    }
    .reverseContainerHeader{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
    .leftMid{
        position: absolute;
        width: 150px;
        height: auto;
        top: 0;
        left:70%;
        transform: rotate(262deg);
    }
    .rightMid{
        position: absolute;
        width: 100px;
        height: auto;
        top: 50%;
        right: 0;
        transform: rotate(160deg);
    }
    .leftTop{
        position: absolute;
        width: 200px;
        height: auto;
        top: 20;
        left: -20;
        transform: rotate(11deg);
    }
  
    .leftBottom{
        position: absolute;
        width: 100px;
        height: auto;
        left: 0;
        bottom: 0;
    }
    .rightBottom{
        position: absolute;
        width: 100px;
        height: auto;
        right: 0;
        bottom: 0;
    }
}

@media screen and (max-height: 850px) and (min-width: 1024px) {
    .wrapper{
        height: fit-content;
        width: -webkit-fill-available;
        overflow-y: scroll;
        padding:76px 96px;
    }
    .innerContainer{
        height: fit-content;
        max-height: fit-content;
        margin: 0;
        padding-bottom: 32px;
  }
}
